<template>
    <span>
        {{ item }}
    </span>
</template>

<script>
import widgetMixins from './widgetMixins.js'
export default {
    mixins: [
        widgetMixins
    ]
}
</script>